import React from "react";


import contactUsBGImg from "../images/contactUsBG.png";
import map from "../images/map.png";
import locationMarkerWhite from "../images/locationMarkerWhite.png";
import phoneWhite from "../images/phoneWhite.png";
import emailWhite from "../images/emailWhite.png";
import Head from "../components/head";
import contactStyles from "./contactus.module.css";

class ContactPage extends React.Component {
  state = { status: "" };

  submitForm = ev => {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  };

  render() {
    return (
      <>
      <Head title="Contact Us"></Head>
        <section className={contactStyles.contactUsBGSection}>
          <div className={contactStyles.contactCopyContainer}>
            <div>
              <h1>Do you have any questions or concerns about TSS?</h1>
              <p>
                Contact us at <strong>admin@thesecretsits.org </strong>
                <br />
                or...
              </p>
              <div style={{ display: "flex" }}>
                <div className={contactStyles.primaryBtn}>Message us below</div>
              </div>
            </div>
          </div>
          <div
            className={contactStyles.contactBgImg}
            style={{ backgroundImage: `url(${contactUsBGImg})` }}
          ></div>
        </section>
        <section className={contactStyles.keepInTouchSection}>
          <form
            className={contactStyles.kITWrapper}
            onSubmit={this.submitForm}
            action="https://formspree.io/xgengzwk"
            method="POST"
          >
            <div className={contactStyles.kITInnerLeft}>
              <h2>Keep in touch</h2>
              {this.state.status === "SUCCESS" ? (
                <p
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Success! Your message has been sent!
                </p>
              ) : null}
              {this.state.status === "ERROR" ? (
                <p
                  style={{
                    backgroundColor: "b00b00",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Oops! Something went wrong please try again!
                </p>
              ) : null}
              <div className={contactStyles.nameEmailContainer}>
                <div className={contactStyles.nameContainer}>
                  <label for="name">Your Name</label>
                  <input
                    name="name"
                    type="text"
                    placeholder="John Smith"
                  ></input>
                </div>
                <div>
                  <label for="email">Email Address</label>
                  <input
                    name="email"
                    type="email"
                    placeholder="John@gmail.com"
                  ></input>
                </div>
              </div>
              <div className={contactStyles.PhoneCompanyContainer}>
                <div className={contactStyles.phoneContainer}>
                  <label for="phone">Phone (optional)</label>
                  <input
                    name="phone"
                    type="tel"
                    placeholder="(770) 789-1234"
                  ></input>
                </div>
                <div>
                  <label for="company">Company (optional)</label>
                  <input
                    name="company"
                    type="text"
                    placeholder="Ex. Amazon"
                  ></input>
                </div>
              </div>

              <div className={contactStyles.messageContainer}>
                <div>
                  <label for="message">Message</label>
                  <textarea name="message" placeholder="Message..."></textarea>
                </div>
              </div>
              <div className={contactStyles.sendBtnContainer}>
                <button type="submit">Send</button>
              </div>
            </div>
            <div className={contactStyles.kITInnerRight}>
              <img src={map}></img>
              <div className={contactStyles.kITInnerRightCopy}>
                <div>
                  <img src={locationMarkerWhite} />
                  <span>177 Chinquapin Way, Athens, GA 30605</span>
                </div>
                <div>
                  <img src={phoneWhite} />
                  <span>(706) 614-7534</span>
                </div>
                <div id={contactStyles.emailContainer}>
                  <img id={contactStyles.email} src={emailWhite} />{" "}
                  <span>admin@thesecretsits.org</span>
                </div>
              </div>
            </div>
          </form>
        </section>
      </>
    );
  }
}
export default ContactPage;
